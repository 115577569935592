<template>
  <div class="flex min-h-screen w-full flex-col justify-between text-gray-800">
    <NavigationMain :nav="navigations.main" :meta="navigations.mainmeta" />

    <main class="flex-1">
      <slot />
    </main>

    <NavigationFooter
      :nav="navigations.footer"
      :globals="navigations.globals"
      :meta="navigations.footermeta"
    />
  </div>
</template>

<script setup>
import { store } from '/store.js'

// Fetch all navigations and globals
const { data: navigations } = await useAsyncGql({
  operation: 'Navigations',
})

if (navigations.value && navigations.value.globals) {
  // populate the store with globals
  store.globals = navigations.value?.globals
  // populate the store with the main nav for the breadcrumbs
  store.navigations = { main: navigations?.value?.main }
}
</script>
